

export default function MainSection(){
    return(
        <p className='App-body'>
            <span className='highlight'>Comming Soon</span><br/>
            <span className='App-Slogan-Heading'>Are you a Friendly Travel Assistant?</span><br/>
            You are, if you love to travel and you also love to help others travel better, cheaper, happier <br/>
        </p>
    );
}