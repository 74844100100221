import logo from '../../logo.svg';
import '../../App.css';

export default function Header(){

    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <span className="App-Name">Friendly Travel Assistants</span>
        </header>
    );
}